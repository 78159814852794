<template>
<body>
    <!-- ::::::  Start Header Section  ::::::  -->
   <Header/>
    <!-- :::::: End Header Section ::::::  -->  
    <!-- ::::::  Start  Breadcrumb Section  ::::::  -->
    <div class="page-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="page-breadcrumb__menu">
                        <li class="page-breadcrumb__nav"><router-link to="/">Home</router-link></li>
                        <li class="page-breadcrumb__nav active">{{detail.name}}</li>
                    </ul>
                </div>
            </div>
        </div>
    </div> <!-- ::::::  End  Breadcrumb Section  ::::::  -->

    <!-- :::::: Start Main Container Wrapper :::::: -->
    <main id="main-container" class="main-container inner-page-container">

        <!-- Start Product Details Gallery -->
        <div class="product-details">
            <div class="container">
                <div class="row">
                    <div class="col-md-5">
                        <div class="product-gallery-box product-gallery-box--default m-b-60">
                            <div class="product-image--large product-image--large-horizontal">
                               <zoom-on-hover :img-normal="$root.URL_ROOT+'uploads/Product/'+mainImage" :scale="2"></zoom-on-hover>
                            </div>
                            <div id="gallery-zoom" class="product-image--thumb product-image--thumb-horizontal pos-relative">
                                 <img v-for="(images,key) in allimages" :key="key" @click="changeImage(images)" :src="$root.URL_ROOT+'uploads/Product/'+images" alt="" class="thumb-img">
                            </div>
                        </div>
                    </div>
                    <div class="col-md-7">
                        <div class="product-details-box m-b-60">
                            <h4 class="font--regular m-b-20">{{detail.name}} </h4>
                            <ul class="product__review">
                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                <li class="product__review--blank"><i class="icon-star"></i></li>
                            </ul>
                            <div class="product__price m-t-5">
                                <span class="product__price product__price--large">INR {{detail.selling_cost}} 
                                <del>
                                    <template v-if="detail.purchese_cost != 0">INR {{detail.purchese_cost}}</template>
                                </del>
                                </span>
                            </div>
                            <div class="product__desc m-t-25 m-b-30">
                                <p v-html="detail.description.substring(0,300)+'....'"></p>
                            </div>
                            <div class="product-var p-tb-30">
                                <div class="product__stock m-b-20">
                                    <span class="product__stock--in" v-if="detail.stock>0"><i class="fas fa-check-circle"></i> IN STOCK</span>
                                    <span class="product__stock--in" v-else><i class="fas fa-check-circle"></i> OUT OF STOCK</span>
                                </div>
                                <div class="product-quantity product-var__item">
                                    <ul class="product-modal-group">
                                        <li><a href="#modalSizeGuide" data-bs-toggle="modal"  class="link--gray link--icon-left"><i class="fal fa-money-check-edit"></i>Size Guide</a></li>
                                        <li><a href="#modalShippinginfo" data-bs-toggle="modal"  class="link--gray link--icon-left"><i class="fal fa-truck-container"></i>Shipping</a></li>
                                        <li><a href="#modalProductAsk" data-bs-toggle="modal"  class="link--gray link--icon-left"><i class="fal fa-envelope"></i>Ask About This product</a></li>
                                    </ul>
                                </div>
                                <div class="unit-box" v-if="detail.unit">
                                    <div class="unit-btn">{{detail.weight+' '+detail.unit}}</div>
                                </div>
                                <div class="product-quantity product-var__item d-flex align-items-center">
                                    <span class="product-var__text">Quantity: </span>
                                    <form class="quantity-scale m-l-20">
                                        <div class="value-button" id="decrease" :class="qty==1 ? 'not-active' : ''" @click="qtyMin">-</div>
                                        <input type="number" id="number" :value="qty" />
                                        <div class="value-button" id="increase" @click="qtyAdd">+</div>
                                    </form>
                                </div>
                                <div class="product-var__item">
                                    <a @click="add_to_cart(detail.id,detail.name,qty,detail.selling_cost)" data-bs-toggle="modal" data-bs-dismiss="modal" class="btn btn--long btn--radius-tiny btn--green btn--green-hover-black btn--uppercase btn--weight m-r-20">Add to cart</a>
                                    <a @click="whishList(detail.id)" class="btn btn--round btn--round-size-small btn--green btn--green-hover-black"><i class="fas fa-heart"></i></a>
                                </div>
                                <div class="product-var__item">
                                    <span class="product-var__text">Guaranteed safe checkout </span>
                                    <ul class="payment-icon m-t-5">
                                        <li><img src="../assets/img/icon/payment/paypal.svg" alt=""></li>
                                        <li><img src="../assets/img/icon/payment/amex.svg" alt=""></li>
                                        <li><img src="../assets/img/icon/payment/ipay.svg" alt=""></li>
                                        <li><img src="../assets/img/icon/payment/visa.svg" alt=""></li>
                                        <li><img src="../assets/img/icon/payment/shoify.svg" alt=""></li>
                                        <li><img src="../assets/img/icon/payment/mastercard.svg" alt=""></li>
                                        <li><img src="../assets/img/icon/payment/gpay.svg" alt=""></li>
                                    </ul>
                                </div>
                                <div class="product-var__item d-flex align-items-center">
                                    <span class="product-var__text">Share: </span>
                                    <ul class="product-social m-l-20">
                                        <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                        <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                        <li><a href="#"><i class="fab fa-pinterest-p"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- End Product Details Gallery -->
        <!-- Start Product Details Tab -->
        <div class="product-details-tab-area">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="product-details-content">
                            <ul class="tablist tablist--style-black tablist--style-title tablist--style-gap-30 nav">
                                <li><a class="nav-link " :class="isShow==1 ? 'active' : ''" @click="isActive(1)" data-bs-toggle="tab" href="#product-desc">Description</a></li>
                                <!-- <li><a class="nav-link"  :class="isShow==2 ? 'active' : ''" id="Ratings-tab" @click="isActive(2)" data-bs-toggle="tab">Reviews</a></li> -->
                            </ul>
                            <div class="product-details-tab-box">
                                <div class="tab-content">
                                    <!-- Start Tab - Product Description -->
                                    <div class="tab-pane show " :class="isShow==1 ? 'active' : ''"  id="product-desc">
                                        <div class="para__content">
                                            <p class="para__text">Use the Canon VIXIA GX10 Camcorder to capture UHD 4K video at 60 fps, recording in MP4 to dual SD memory card slots. This camcorder packs several pro-style features into its compact form, including Dual-Pixel Autofocus (DPAF). The GX10's 1" 8.29MP CMOS sensor and dual DIGIC DV 6 image processors support Wide DR Gamma with high sensitivity and low noise. Slow and fast-motion recording up to 120 fps offers special looks for highlighting sports and other special events. Smooth, steady shooting is assisted by the GX10's five-axis optical image stabilization. For composing and viewing your footage, the VIXIA GX10 incorporates a flip-out 3.5" touchscreen LCD, and a 0.24" electronic viewfinder. </p>
                                            <p class="para__text">Additional GX10 features include an HDMI 2.0 port for outputting your 4K UHD footage, assignable user buttons, and remote control using the included WL-D89 controller. Wi-Fi connectivity offers live streaming, FTP file sharing, and remote control via iOS and Android apps.</p>
                                            <h6 class="para__title">Product Highlights:</h6>
                                            <ul class="para__list">
                                                <li>UHD 4K Output up to 60 fps</li>
                                                <li>8.29MP, 1" CMOS Sensor</li>
                                                <li>Dual-Pixel CMOS Autofocus Feature</li>
                                                <li>Integrated 15x Optical Zoom Lens</li>
                                                <li>2 x DIGIC DV 6 Processors</li>
                                                <li>5-Axis Optical Image Stabilization</li>
                                                <li>Wide Dynamic Range Support</li>
                                                <li>Records 4K UHD/HD to Dual SD Card Slots</li>
                                                <li>3.5" Touchscreen LCD &amp; 0.24" EVF</li>
                                                <li>Live Stream/FTP/Remote App via Wi-Fi</li>
                                            </ul>
                                        </div>    
                                    </div>  <!-- End Tab - Product Description -->
                                    <!-- Start Tab - Product Review -->
                                    <div class="tab-pane " :class="isShow==2 ? 'active' : ''"  id="product-review">
                                        <!-- Start - Review Comment -->
                                        <ul class="comment">
                                            <!-- Start - Review Comment list-->
                                            <li class="comment__list">
                                                <div class="comment__wrapper">
                                                    <div class="comment__img">
                                                        <img src="assets/img/user/image-1.png" alt=""> 
                                                    </div>
                                                    <div class="comment__content">
                                                        <div class="comment__content-top">
                                                            <div class="comment__content-left">
                                                                <h6 class="comment__name">Kaedyn Fraser</h6>
                                                                <ul class="product__review">
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--blank"><i class="icon-star"></i></li>
                                                            </ul>
                                                            </div>   
                                                            <div class="comment__content-right">
                                                                <a href="#" class="link--gray link--icon-left m-b-5"><i class="fas fa-reply"></i>Reply</a>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="para__content">
                                                            <p class="para__text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora inventore dolorem a unde modi iste odio amet, fugit fuga aliquam, voluptatem maiores animi dolor nulla magnam ea! Dignissimos aspernatur cumque nam quod sint provident modi alias culpa, inventore deserunt accusantium amet earum soluta consequatur quasi eum eius laboriosam, maiores praesentium explicabo enim dolores quaerat! Voluptas ad ullam quia odio sint sunt. Ipsam officia, saepe repellat. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <!-- Start - Review Comment Reply-->
                                                <ul class="comment__reply">
                                                    <li class="comment__reply-list">
                                                        <div class="comment__wrapper">
                                                            <div class="comment__img">
                                                                <img src="assets/img/user/image-2.png" alt=""> 
                                                            </div>
                                                            <div class="comment__content">
                                                                <div class="comment__content-top">
                                                                    <div class="comment__content-left">
                                                                        <h6 class="comment__name">Oaklee Odom</h6>
                                                                    </div>   
                                                                    <div class="comment__content-right">
                                                                        <a href="#" class="link--gray link--icon-left m-b-5"><i class="fas fa-reply"></i>Reply</a>
                                                                    </div>
                                                                </div>
                                                                
                                                                <div class="para__content">
                                                                    <p class="para__text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora inventore dolorem a unde modi iste odio amet, fugit fuga aliquam, voluptatem maiores animi dolor nulla magnam ea! Dignissimos aspernatur cumque nam quod sint provident modi alias culpa, inventore deserunt accusantium amet earum soluta consequatur quasi eum eius laboriosam, maiores praesentium explicabo enim dolores quaerat! Voluptas ad ullam quia odio sint sunt. Ipsam officia, saepe repellat. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul> <!-- End - Review Comment Reply-->
                                            </li> <!-- End - Review Comment list-->
                                            <!-- Start - Review Comment list-->
                                            <li class="comment__list">
                                                <div class="comment__wrapper">
                                                    <div class="comment__img">
                                                        <img src="assets/img/user/image-3.png" alt=""> 
                                                    </div>
                                                    <div class="comment__content">
                                                        <div class="comment__content-top">
                                                            <div class="comment__content-left">
                                                                <h6 class="comment__name">Jaydin Jones</h6>
                                                                <ul class="product__review">
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--blank"><i class="icon-star"></i></li>
                                                            </ul>
                                                            </div>   
                                                            <div class="comment__content-right">
                                                                <a href="#" class="link--gray link--icon-left m-b-5"><i class="fas fa-reply"></i>Reply</a>
                                                            </div>
                                                        </div>
                                                        
                                                        <div class="para__content">
                                                            <p class="para__text">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Tempora inventore dolorem a unde modi iste odio amet, fugit fuga aliquam, voluptatem maiores animi dolor nulla magnam ea! Dignissimos aspernatur cumque nam quod sint provident modi alias culpa, inventore deserunt accusantium amet earum soluta consequatur quasi eum eius laboriosam, maiores praesentium explicabo enim dolores quaerat! Voluptas ad ullam quia odio sint sunt. Ipsam officia, saepe repellat. </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li> <!-- End - Review Comment list-->
                                        </ul>  <!-- End - Review Comment -->

                                        <!-- Start Add Review Form-->
                                        <div class="review-form m-t-40">
                                            <div class="section-content">
                                                <h6 class="font--bold text-uppercase">ADD A REVIEW</h6>
                                                <p class="section-content__desc">Your email address will not be published. Required fields are marked *</p>
                                            </div>
                                            <form class="form-box" action="#" method="post">
                                                <div class="row">
                                                    <div class="col-12">
                                                        <div class="form-box__single-group">
                                                            <label for="form-name">Your Rating*</label>
                                                            <ul class="product__review">
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--fill"><i class="icon-star"></i></li>
                                                                <li class="product__review--blank"><i class="icon-star"></i></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="form-name">Your Name*</label>
                                                            <input type="text" id="form-name" placeholder="Enter your name">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-box__single-group">
                                                            <label for="form-email">Your Email*</label>
                                                            <input type="email" id="form-email" placeholder="Enter your email" required>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <div class="form-box__single-group">
                                                            <label for="form-review">Your review*</label>
                                                            <textarea id="form-review" rows="8" placeholder="Write a review"></textarea>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <button class="btn btn--box btn--small btn--black btn--black-hover-green btn--uppercase font--bold m-t-30" type="submit">Submit</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div> <!-- End Add Review Form- -->
                                    </div>  <!-- Start Tab - Product Review -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>  <!-- End Product Details Tab -->

        <!-- ::::::  Start  Product Style - Default Section  ::::::  -->
        <div class="product m-t-100">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                         <!-- Start Section Title -->
                        <div class="section-content section-content--border m-b-35">
                            <h5 class="section-content__title">Related Product
                            </h5>
                        </div>  <!-- End Section Title -->
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="default-slider default-slider--hover-bg-red product-default-slider">
                            <div class="product-default-slider-4grid-1rows gap__col--30 gap__row--40">

                                <!-- Start Single Default Product -->
                                <Product :productData="detail.related" />

                             <!-- End Single Default Product -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> <!-- ::::::  End  Product Style - Default Section  ::::::  -->
    </main>  <!-- :::::: End MainContainer Wrapper :::::: -->
    <!-- ::::::  Start  Footer ::::::  -->
    <Footer/>
    <!-- ::::::  End  Footer ::::::  -->
</body>
</template>

<script>
import axios from "axios";
import Header from "@/components/navbar";
import Footer from "@/components/footer";
import Rating from "@/components/starRating";
import Product from '../components/product.vue';


import imageZoom from 'vue-image-zoomer';
export default {
    components: {
        Header,
        Footer,
        Rating,
        imageZoom,
        Product
    },
    data() {
        return {
            ratingDisable: false,
            userRating: [],
            isShow: 1,
            detail: [],
            ratingProduct: 0,
            uid: localStorage.getItem("user"),
            product_id: this.$route.params.id,
            qty: 1,
            mainImage: '',
            allimages: ''
        };
    },
    mounted() {
        this.productDetail();
        this.check_rating()
        this.goToTop()

    },

    watch: {
        searchingVar(value) {
            this.searchingVar = value;
            this.search();
        },
        'ratingProduct': function (newVal) {
            if (newVal > 0) {
                this.reviewRating()
            }
        },
        "$route.params.id": function (oldVal, newVal) {
            if (oldVal != newVal) {
                this.goToTop()
                this.productDetail();
            }

        },
        "detail": function (newVal) {
            this.detail = newVal
        }
    },
    methods: {
        qtyAdd() {
            this.qty = this.qty + 1
        },

        qtyMin() {
            this.qty = this.qty - 1
        },
        goToTop() {
            document.body.scrollTop = 0
            document.documentElement.scrollTop = 0
        },
        isActive(id) {
            this.isShow = id
        },
        changeImage(img) {
            this.mainImage = img
        },
        check_rating() {
            var self = this
            axios({
                method: "POST",
                url: this.$root.URL_ROOT + "api.php",
                data: {
                    type: "getRatingByUser",
                    pid: self.$route.params.id,
                    uid: self.uid,
                },
            }).then(function (response) {
                var data = response.data;
                if (data.data) {
                    console.log('getRatingByUser', data);
                    self.ratingProduct = data.data.rating
                    self.ratingDisable = true
                } else {
                    self.ratingDisable = false
                }
            });
        },
        async productDetail() {
            var ev = this;
            var ip_address = JSON.parse(localStorage.getItem("ip_address"));
            var user_session = JSON.parse(localStorage.getItem("user"));
            var uid = "";
            if (user_session != null) {
                uid = user_session;
            } else {
                uid = null;
            }
            const response = await axios.post(
                this.$root.URL_ROOT + "api.php?type=getSingleProduct", {
                    id: ev.product_id,
                    uid: uid,
                    ip_address: ip_address
                }
            );
            var data = response.data;
            // console.log(data)
            if (data.status == "success") {
                ev.detail = data.product;
                ev.mainImage = ev.detail.front_image;
                ev.allimages = JSON.parse(ev.detail.image_collection);

            }
        },
        reviewRating() {
            var self = this;
            if (this.uid == '' || this.uid == null) {
                self.$toasted.global.success({
                    message: 'Please login first to give rating'
                })
            } else {
                axios({
                    method: "POST",
                    url: this.$root.URL_ROOT + "api.php",
                    data: {
                        type: "addRating",
                        pid: self.detail.id,
                        uid: self.uid,
                        rating: self.ratingProduct,
                    },
                }).then(function (response) {
                    var data = response.data;
                    console.log('feedback', data)
                    if (data.status == 'success') {
                        //      self.$toasted.global.success({
                        // message: 'Thankyou for feedback'
                        //  })
                        self.userRating = data.data
                    } else if (data.status == 'error') {
                        // self.userRating = data.data
                    }
                });
            }

        },
    },
};
</script>

<style scoped>
.swiper {
    width: 100%;
    height: 100%;
}

.swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;

    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
}

.swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}
</style>
